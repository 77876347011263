<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="机构名称" prop="orgName">
            <el-input v-model="form.orgName" placeholder="请输入机构名称" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="机构员工" prop="orgStaff">
            <el-input v-model="form.orgStaff" placeholder="请输入机构员工" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="负责人" prop="principalPersonName">
            <el-input v-model="form.principalPersonName" placeholder="请输入负责人" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="施工资质" prop="qualificationDesc">
            <el-input v-model="form.qualificationDesc" placeholder="请输入施工资质" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="tel">
            <el-input v-model="form.tel" placeholder="请输入联系方式" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="办公地址" prop="address">
            <el-input v-model="form.address" placeholder="请输入办公地址" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="营业执照图片" prop="businessLicense">
            <upload-img :limit="1" :upload-lists="businessLicenseList" @uploadChange="uploadSuccess1" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="公司从业资质图片" prop="professionalQualification">
            <upload-img :limit="1" :upload-lists="professionalQualificationList" @uploadChange="uploadSuccess2" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="施工资质图片" prop="qualificationImg">
            <upload-img :limit="6" :upload-lists="imgsList" @uploadChange="uploadSuccess" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'

export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      },
      titleName: '',
      imgsList: [],
      natureList: [],
      businessLicenseList: [],
      professionalQualificationList: [],
      form: {},
      rules: {
        orgName: [{ required: true, message: '请输入机构名称', trigger: 'blur' }],
        orgStaff: [{ required: true, message: '请输入机构员工', trigger: 'blur' }],
        principalPersonName: [{ required: true, message: '请输入负责人', trigger: 'blur' }],
        qualificationDesc: [{ required: true, message: '请输入施工资质', trigger: 'blur' }],
        tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系方式格式' }
        ],
        address: [{ required: true, message: '请输入办公地址', trigger: 'blur' }]
      }
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['downloadFile', 'addOrSaveBedCompany', 'detailBedCompany']),
    getCurrentRow(row) {
      this.form.serviceSiteId = row.id
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
    },
    async getDetail() {
      if (this.routeType == 'add') {
        this.titleName = '添加施工单位'
      } else {
        this.titleName = '修改施工单位'
        this.itemData = JSON.parse(this.$route.query.item)
        // 这里调用请求编辑时的详情接口渲染form表单数据

        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'detailBedCompany'
        await this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              orgName: data.orgName,
              orgStaff: data.orgStaff,
              id: data.id,
              principalPersonName: data.principalPersonName,
              qualificationDesc: data.qualificationDesc,
              qualificationImg: data.qualificationImg,
              tel: data.tel,
              address: data.address,
              businessLicense: data.businessLicense,
              professionalQualification: data.professionalQualification
            }
          }
        })
        if (this.form.qualificationImg) {
          this.getFileDetail(this.form.qualificationImg)
        }
        if (this.form.businessLicense) {
          this.getFileDetail1(this.form.businessLicense)
        }
        if (this.form.professionalQualification) {
          this.getFileDetail2(this.form.professionalQualification)
        }
      }
    },
    getFileDetail1(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.businessLicenseList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    getFileDetail2(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.professionalQualificationList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.businessLicense = data.join(',')
    },
    uploadSuccess2(data) {
      this.form.professionalQualification = data.join(',')
    },
    uploadSuccess(data) {
      this.form.qualificationImg = data.join(',')
    },
    getFileDetail(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.imgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    submitEvent(formName) {
      if (!this.form.qualificationImg) {
        this.$XModal.message({
          message: '请上传施工资质图片',
          status: 'warning'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          params = this.form
          const server = 'addOrSaveBedCompany'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
